import { differenceInHours, isAfter } from "date-fns";

import { ShiftBottomSheetBookabilityCriteriaCard } from "./BookabilityCriteriaCard";

interface ShiftBottomSheetUrgentShiftCardProps {
  startDate: Date;
  discardLatenessBooktimeThreshold: number;
}

export function ShiftBottomSheetUrgentShiftCard(props: ShiftBottomSheetUrgentShiftCardProps) {
  const { startDate, discardLatenessBooktimeThreshold } = props;

  const now = new Date();
  const isInProgress = isAfter(now, startDate);
  const isWithinThreshold = differenceInHours(startDate, now) <= discardLatenessBooktimeThreshold;

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      intent="warning"
      title={
        isInProgress
          ? "This is an urgent shift. Please leave as soon as possible."
          : "This is an urgent shift"
      }
      description={
        isWithinThreshold || isInProgress
          ? "Your arrival time will not affect your attendance score unless you no-show."
          : ""
      }
      illustrationType="hours-limit"
    />
  );
}
