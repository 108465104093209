import { logError } from "@src/appV2/lib/analytics";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { invalidateShifts } from "../../invalidateShifts";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { ShiftBottomSheetBookabilityCriteriaCard } from "./BookabilityCriteriaCard";

export function ShiftFailedLoadingCard() {
  const { shiftId } = useShiftModalsDataContext();

  const queryClient = useQueryClient();

  useEffect(() => {
    // If the shift could not be loaded, we're probably displaying an
    // outdated open shifts list with unbookable shifts. We need to
    // invalidate the data so that the list is updated.
    void invalidateShifts(queryClient);

    logError(
      APP_V2_USER_EVENTS.VIEWED_CANT_BOOK_SHIFT_CTA,
      {
        metadata: { shiftId },
        error: new Error("Shift failed to load"),
      },
      true
    );
  }, [queryClient, shiftId]);

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      intent="error"
      title="Something went wrong"
      description="We're having trouble loading this shift. Our engineers have been notified and will fix this as soon as possible."
      illustrationType="fail"
    />
  );
}
