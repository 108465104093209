import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { type WorkerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadata } from "@src/appV2/OpenShifts/ShiftAction/types";
import { SKILLS_ASSESSMENT_BASE_PATH } from "@src/appV2/SkillsAssessment/paths";
import { useHistory } from "react-router-dom";

import { Button } from "../../../components/Button";
import { ShiftBottomSheetBookabilityCriteriaCard } from "../BookabilityCriteriaCard";

interface SkillAssessmentUnderReviewCardProps {
  metadata: WorkerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadata;
}

export function SkillAssessmentUnderReviewCard(props: SkillAssessmentUnderReviewCardProps) {
  const {
    metadata: { qualificationName, qualificationId },
  } = props;
  const history = useHistory();

  useLogEffect(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_UNDER_REVIEW_DIALOG_VIEWED, {
    qualificationName,
    qualificationId,
  });

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title="Your assessment is being reviewed"
      description={`Your assessment is currently being reviewed. We'll share the results with you within 1
        business day. Continue for more details.`}
      illustrationType="quiz-required"
      footer={
        <Button
          fullWidth
          variant="contained"
          size="medium"
          onClick={() => {
            history.push(SKILLS_ASSESSMENT_BASE_PATH);
          }}
        >
          Continue
        </Button>
      }
    />
  );
}
