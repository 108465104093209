import { ShiftBottomSheetBookabilityCriteriaCard } from "./BookabilityCriteriaCard";

export function ShiftBottomSheetPriorityLegacyShiftCard() {
  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      intent="priority"
      title="Priority Access to this shift!"
      description="You're getting early access to this shift because the facility has rated you as a Favorite."
      illustrationType="priority"
    />
  );
}
