import { ShiftType } from "../constants";
import { PriorityShiftPill } from "../Priority/Pill";
import { PriorityPlusShiftPill } from "../Priority/PlusPill";
import { UrgentShiftPill } from "../Urgent/Pill";

interface ShiftBottomSheetHeaderPillProps {
  shiftType: ShiftType;
}

/**
 * Renders pill related to the open shift
 * - Urgent
 * - Priority
 * - Priority Plus (A-Team)
 */
export function ShiftBottomSheetHeaderPill(props: ShiftBottomSheetHeaderPillProps) {
  const { shiftType } = props;

  const showUrgentPill = shiftType === ShiftType.URGENT;

  const showPriorityPill =
    shiftType === ShiftType.PRIORITY || shiftType === ShiftType.PRIORITY_LEGACY;

  const showPriorityPlusPill = shiftType === ShiftType.PRIORITY_PLUS;

  if (showUrgentPill) {
    return <UrgentShiftPill variant="icon-highlight" size="medium" />;
  }

  if (showPriorityPill) {
    return <PriorityShiftPill variant="icon-highlight" size="medium" />;
  }

  if (showPriorityPlusPill) {
    return <PriorityPlusShiftPill variant="icon-highlight" size="medium" />;
  }

  return null;
}
