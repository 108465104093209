import { type WorkerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadata } from "@src/appV2/OpenShifts/ShiftAction/types";
import { SkillsAssessmentWorkerStatus } from "@src/appV2/SkillsAssessment/types";
import { isUserAllowedToTakeAssessment } from "@src/appV2/SkillsAssessment/utils";

import { SkillAssessmentErrorState } from "./SkillAssessmentErrorState";
import { SkillAssessmentFailedCard } from "./SkillAssessmentFailedCard";
import { SkillAssessmentSchedulingCard } from "./SkillAssessmentSchedulingCard";
import { SkillAssessmentUnderReviewCard } from "./SkillAssessmentUnderReviewCard";

interface SkillAssessmentsRequiredCardProps {
  metadata: WorkerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadata;
}

export function SkillAssessmentsRequiredCard(props: SkillAssessmentsRequiredCardProps) {
  const { metadata } = props;

  const { status, retakeAllowedAfter } = metadata;

  if (
    isUserAllowedToTakeAssessment({
      status,
      retakeAllowedAfter,
    })
  ) {
    return <SkillAssessmentSchedulingCard metadata={metadata} />;
  }

  if (status === SkillsAssessmentWorkerStatus.UNDER_REVIEW) {
    return <SkillAssessmentUnderReviewCard metadata={metadata} />;
  }

  if (status === SkillsAssessmentWorkerStatus.FAILED) {
    return <SkillAssessmentFailedCard metadata={metadata} />;
  }

  // This should never happen, but we need to handle it just in case
  return <SkillAssessmentErrorState metadata={metadata} />;
}
