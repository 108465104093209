import { ShiftBottomSheetBookabilityCriteriaCard } from "./BookabilityCriteriaCard";

export function ShiftBottomSheetPriorityPlusShiftCard() {
  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      intent="success"
      title="You have Priority Plus at this workplace!"
      description="You're one of the few select professionals the workplace has chosen to get early access to shifts!"
      illustrationType="priority-plus"
    />
  );
}
