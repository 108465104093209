import { hasUnmetBookabilityCriteria } from "@src/appV2/OpenShifts/ShiftAction/hasUnmetBookabilityCriteria";
import { BookabilityUnmetCriteria } from "@src/appV2/OpenShifts/ShiftAction/types";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { invalidateShifts } from "../../invalidateShifts";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { ShiftBottomSheetBookabilityCriteriaCard } from "./BookabilityCriteriaCard";

export function ShiftNotBookableCard() {
  const { bookabilityStatus } = useShiftModalsDataContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    // We're displaying an outdated open shifts list with unbookable shifts.
    // We need to invalidate the data so that the list is updated.
    void invalidateShifts(queryClient);
  }, [queryClient]);

  const isShiftAlreadyGrabbed = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.SHIFT_ALREADY_GRABBED,
  ]);

  if (isShiftAlreadyGrabbed) {
    return (
      <ShiftBottomSheetBookabilityCriteriaCard
        intent="error"
        title="Shift already booked"
        description="Apologies for the inconvenience. This shift was already booked by someone else. Check out other shifts in your area."
        illustrationType="error"
      />
    );
  }

  const isShiftDeleted = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.SHIFT_DELETED,
  ]);

  if (isShiftDeleted) {
    return (
      <ShiftBottomSheetBookabilityCriteriaCard
        intent="error"
        title="Shift cancelled"
        description="Apologies for the inconvenience. This shift was cancelled by the workplace and is no longer available."
        illustrationType="error"
      />
    );
  }

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      intent="error"
      title="Shift unavailable"
      description="Apologies for the inconvenience. This shift is no longer available. Check out other shifts in your area."
      illustrationType="error"
    />
  );
}
