import { formatDate } from "@clipboard-health/date-time";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { type WorkerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadata } from "@src/appV2/OpenShifts/ShiftAction/types";
import { SKILLS_ASSESSMENT_BASE_PATH } from "@src/appV2/SkillsAssessment/paths";
import { useHistory } from "react-router-dom";

import { Button } from "../../../components/Button";
import { ShiftBottomSheetBookabilityCriteriaCard } from "../BookabilityCriteriaCard";

interface SkillAssessmentFailedCardProps {
  metadata: WorkerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadata;
}

export function SkillAssessmentFailedCard(props: SkillAssessmentFailedCardProps) {
  const {
    metadata: { qualificationName, qualificationId, retakeAllowedAfter },
  } = props;
  const history = useHistory();

  useLogEffect(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_FAILED_DIALOG_VIEWED, {
    qualificationName,
    qualificationId,
  });

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title="You did not pass the assessment"
      description={
        <>
          Unfortunately your submission didn&apos;t meet the requirements for passing this
          assessment.
          {/* eslint-disable-next-line no-restricted-syntax */}
          <br />
          You&apos;ll have the opportunity to schedule another assessment
          {retakeAllowedAfter ? ` on ${formatDate(retakeAllowedAfter)}` : ""}. You&apos;ll still be
          able to work any shifts that you&apos;ve already booked.
        </>
      }
      illustrationType="quiz-required"
      footer={
        <Button
          fullWidth
          variant="contained"
          size="medium"
          onClick={() => {
            history.push(SKILLS_ASSESSMENT_BASE_PATH);
          }}
        >
          Go to results
        </Button>
      }
    />
  );
}
