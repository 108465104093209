import { type UseModalState } from "@clipboard-health/ui-react";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { useDeviceGeoLocationIfAllowed } from "@src/appV2/Location";
import { useIsDeviceLocationPermissionGranted } from "@src/appV2/Location/deviceLocation/geoLocation";

import { SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { type ShiftBreakModalRoutePathParams } from "../../ShiftDiscovery/types";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { PreBookingCheck, useBookShift } from "../Booking/useBookShift";
import { BreakConfirmationBottomSheet } from "./BreakConfirmationBottomSheet";

interface BreakConfirmationModalRouteContainerProps {
  shiftId: string;
  shiftOfferId?: string;
  workplaceId?: string;
  modalState: UseModalState;
}

export function BreakConfirmationModalRouteContainer(
  props: BreakConfirmationModalRouteContainerProps
) {
  const { modalState, shiftId, shiftOfferId, workplaceId } = props;
  const { navigateToModal, modalRouteParams } = useShiftModalsDataContext();

  const noteId = (modalRouteParams as ShiftBreakModalRoutePathParams)?.noteId;

  // We need the device's geolocation, otherwise we can't let the user claim the shift
  const {
    data: isDeviceLocationPermissionGranted,
    isLoading: isDeviceLocationPermissionGrantedLoading,
  } = useIsDeviceLocationPermissionGranted();

  const geolocationIsUnavailable = !isDeviceLocationPermissionGranted && isCapacitorPlatform();

  const { data: deviceGeolocation, isInitialLoading: isDeviceGeolocationLoading } =
    useDeviceGeoLocationIfAllowed({
      enabled: !geolocationIsUnavailable,
    });

  const { attemptBookingShift, isBooking } = useBookShift({
    shiftId,
    shiftOfferId,
    navigateToModal,
    workplaceId,
    onBook: () => {
      navigateToModal(SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH, { shiftId });
    },
  });

  const isLoading =
    isBooking || isDeviceGeolocationLoading || isDeviceLocationPermissionGrantedLoading;

  return (
    <BreakConfirmationBottomSheet
      modalState={modalState}
      shiftId={shiftId}
      workplaceId={workplaceId}
      breakPolicyNoteId={noteId}
      isLoading={isLoading}
      onAcknowledge={async () => {
        await attemptBookingShift({
          geoLocation: deviceGeolocation?.geoLocation,
          // We are skipping this pre-booking check because we just acknowledged the break policy
          // In the `attemptBookingShift` function, the state (that the policy has been acknowledged)
          // is not updated yet when we make this call, so we explicitly skip this check.
          skipChecks: [PreBookingCheck.BREAK_POLICY_ACKNOWLEDGEMENT],
        });
      }}
    />
  );
}
