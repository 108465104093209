import { SIGN_NEW_AGREEMENT_PATH } from "@src/appV2/Agreements/paths";
import { RootPaths } from "@src/appV2/App/paths";
import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { useHistory } from "react-router-dom";

import { Button } from "../../components/Button";
import { QuickSignPill } from "../Bookability/Documents/InstantReview/QuickSignPill";
import { ShiftBottomSheetBookabilityCriteriaCard } from "./BookabilityCriteriaCard";

interface ShiftBottomSignAgreementCardProps {
  shiftId: string;
}

export function ShiftBottomSignAgreementCard(props: ShiftBottomSignAgreementCardProps) {
  const { shiftId } = props;
  const history = useHistory();

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      pill={<QuickSignPill />}
      title="Sign the new agreement"
      description="A new version of the independent contractor agreement is required to be signed in order to book this shift."
      illustrationType="quiz-required"
      footer={
        <Button
          fullWidth
          variant="contained"
          size="medium"
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CTA_SIGN_AGREEMENT, {
              shiftId,
            });

            history.push(`${RootPaths.HOME}/${SIGN_NEW_AGREEMENT_PATH}`);
          }}
        >
          Sign agreement
        </Button>
      }
    />
  );
}
