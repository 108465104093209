import { ShiftBottomSheetBookabilityCriteriaCard } from "./BookabilityCriteriaCard";

interface ShiftBottomSheetPriorityShiftCardProps {
  isClipboardScore: boolean;
}

export function ShiftBottomSheetPriorityShiftCard(props: ShiftBottomSheetPriorityShiftCardProps) {
  const { isClipboardScore } = props;

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      intent="priority"
      title="You have High Score Priority Access to this shift!"
      description={
        isClipboardScore
          ? "You're getting early access to this shift because you have a high Clipboard Score. Keep it up!"
          : "You're getting early access to this shift because of your strong track marketplace track record. Keep it up!"
      }
      illustrationType="priority"
    />
  );
}
