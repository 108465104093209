import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { type WorkerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadata } from "@src/appV2/OpenShifts/ShiftAction/types";
import { SKILLS_ASSESSMENT_BASE_PATH } from "@src/appV2/SkillsAssessment/paths";
import { useHistory } from "react-router-dom";

import { Button } from "../../../components/Button";
import { ShiftBottomSheetBookabilityCriteriaCard } from "../BookabilityCriteriaCard";

interface SkillAssessmentSchedulingCardProps {
  metadata: WorkerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadata;
}

export function SkillAssessmentSchedulingCard(props: SkillAssessmentSchedulingCardProps) {
  const {
    metadata: { qualificationName, qualificationId },
  } = props;
  const history = useHistory();

  useLogEffect(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_REQUIREMENT_DIALOG_VIEWED, {
    qualificationName,
    qualificationId,
  });

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title="Skills assessment required"
      description={`To book ${qualificationName} shifts, complete a quick video assessment. Schedule your time now.`}
      illustrationType="quiz-required"
      footer={
        <Button
          fullWidth
          variant="contained"
          size="medium"
          onClick={() => {
            logEvent(
              APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_REQUIREMENT_DIALOG_CONTINUE_BUTTON_CLICKED,
              {
                qualificationName,
                qualificationId,
              }
            );

            history.push(SKILLS_ASSESSMENT_BASE_PATH);
          }}
        >
          Continue
        </Button>
      }
    />
  );
}
