import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { openZendeskMessaging } from "@src/appV2/lib/ZendeskMessaging/openZendeskMessaging";
import { type WorkerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadata } from "@src/appV2/OpenShifts/ShiftAction/types";

import { Button } from "../../../components/Button";
import { ShiftBottomSheetBookabilityCriteriaCard } from "../BookabilityCriteriaCard";

interface SkillAssessmentErrorStateProps {
  metadata: WorkerHasNotPassedAssessmentBookabilityUnmetCriteriaMetadata;
}

export function SkillAssessmentErrorState(props: SkillAssessmentErrorStateProps) {
  const { metadata } = props;

  useLogEffect(APP_V2_USER_EVENTS.SKILLS_ASSESSMENT_UNEXPECTED_ERROR_DIALOG_VIEWED, {
    ...metadata,
  });

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title="We've experienced an error"
      description="There's been an error with the skills assessment. Please contact support and we'll resolve this issue for you."
      illustrationType="quiz-required"
      footer={
        <Button
          fullWidth
          variant="contained"
          size="medium"
          onClick={async () => {
            await openZendeskMessaging();
          }}
        >
          Contact Support
        </Button>
      }
    />
  );
}
