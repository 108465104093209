import {
  List,
  ListItem,
  ListItemIconSkeleton,
  ListItemTextSkeleton,
  PillSkeleton,
} from "@clipboard-health/ui-components";
import { Box, CardContent, Skeleton, Stack } from "@mui/material";

import { WorkplaceHighlightPills } from "../../Workplace/Highlights/Pills";
import { ShiftBottomSheetCardsWrapper } from "./CardsWrapper";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

export function ShiftBottomSheetSkeleton() {
  return (
    <Box sx={{ overflowY: "auto" }}>
      <ShiftBottomSheetCardsWrapper>
        <ShiftBottomSheetInnerCard>
          <CardContent
            sx={{
              padding: 6,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 7,
            }}
          >
            <PillSkeleton />
            <Skeleton variant="text" width="70%" height="25%" />
            <Skeleton variant="text" width="85%" height="25%" />
            <Skeleton variant="text" width="65%" height="25%" />
          </CardContent>
        </ShiftBottomSheetInnerCard>
      </ShiftBottomSheetCardsWrapper>

      <List sx={{ px: 6 }}>
        <ListItem divider>
          <ListItemIconSkeleton />
          <ListItemTextSkeleton primaryWidth="35%" secondaryWidth="30%" />
        </ListItem>

        <ListItem sx={{ paddingBottom: 4 }}>
          <ListItemIconSkeleton />
          <ListItemTextSkeleton primaryWidth="60%" secondaryWidth="55%" />
        </ListItem>

        <Stack spacing={4} sx={{ paddingTop: 4, ml: "2rem" }}>
          <WorkplaceHighlightPills isLoading highlights={[]} />

          <Skeleton sx={{ height: "13rem", width: "100%" }} />
        </Stack>
      </List>
    </Box>
  );
}
