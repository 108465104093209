import { List } from "@clipboard-health/ui-components";
import { Box } from "@mui/material";
import { type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";

import { WorkingThisShiftListItem } from "../../WorkWithFriends/components/WorkingThisShiftListItem";
import { type WorkerPublicProfile } from "../../WorkWithFriends/hooks/useGetWorkerPublicProfile";
import { type ShiftType } from "../constants";
import { type TimeSlot } from "../Open/types";
import { type ShiftSlotCoalesced } from "../Open/useGetShiftsSlotV2";
import type { ModalWorkplaceData } from "../types";
import { ShiftBottomSheetBookabilityCardsContainer } from "./BookabilityCardsContainer";
import { ShiftBottomSheetDurationListItem } from "./DurationListItem";
import { ShiftBottomSheetHighlightsAndMapListItem } from "./HighlightsAndMapListItem";
import { ShiftBottomSheetWorkplaceListItem } from "./WorkplaceListItem";

interface ShiftBottomSheetSheetContentProps {
  shiftId: string;
  shiftType: ShiftType;
  shiftTimeSlot: TimeSlot;
  shiftStartIso: string;
  shiftEndIso: string;
  shiftDurationInHours: number;
  workplace: ModalWorkplaceData;
  bookabilityStatus: BookabilityStatusItem;
  hasBlockingRequirements: boolean;
  hasMarkedInterest?: boolean;
  shiftFailedLoading: boolean;
  hasHoursRestrictionConflict: boolean;
  shiftSlots?: ShiftSlotCoalesced;
  friendsMap?: Map<string, WorkerPublicProfile>;
}

export function ShiftBottomSheetContent(props: ShiftBottomSheetSheetContentProps) {
  const {
    shiftId,
    shiftTimeSlot,
    shiftStartIso,
    shiftEndIso,
    shiftDurationInHours,
    workplace,
    bookabilityStatus,
    shiftType,
    hasHoursRestrictionConflict,
    shiftFailedLoading,
    hasMarkedInterest,
    hasBlockingRequirements,
    shiftSlots,
    friendsMap,
  } = props;

  const {
    name: workplaceName,
    lateCancellation,
    location: workplaceLocation,
    requiresLunchBreak,
    providesRestBreaks,
  } = workplace.attributes;

  const { period: lateCancellationPeriod, feeHours: lateCancellationFeeHours } =
    lateCancellation ?? {};

  return (
    <Box sx={{ overflowY: "auto" }}>
      <ShiftBottomSheetBookabilityCardsContainer
        shiftId={shiftId}
        shiftType={shiftType}
        shiftStartIso={shiftStartIso}
        workplace={workplace}
        bookabilityStatus={bookabilityStatus}
        hasHoursRestrictionConflict={hasHoursRestrictionConflict}
        shiftFailedLoading={shiftFailedLoading}
        hasMarkedInterest={hasMarkedInterest}
        hasBlockingRequirements={hasBlockingRequirements}
      />

      <List sx={{ px: 6 }}>
        <ShiftBottomSheetDurationListItem
          shiftTimeSlot={shiftTimeSlot}
          shiftStartIso={shiftStartIso}
          shiftEndIso={shiftEndIso}
          shiftDurationInHours={shiftDurationInHours}
          workplaceTimeZone={workplaceLocation.timezone}
        />

        <ShiftBottomSheetWorkplaceListItem
          workplaceId={workplace.id}
          workplaceName={workplaceName}
          workplaceType={workplace.attributes.type ?? "America/Los_Angeles"}
          workplaceLocation={workplaceLocation}
        />

        <ShiftBottomSheetHighlightsAndMapListItem
          workplaceId={workplace.id}
          workplaceName={workplaceName}
          workplaceLocation={workplaceLocation}
          lateCancellationPeriod={lateCancellationPeriod ?? 0}
          lateCancellationFeeHours={lateCancellationFeeHours ?? 0}
          requiresLunchBreak={requiresLunchBreak}
          providesRestBreaks={providesRestBreaks}
        />

        <WorkingThisShiftListItem shiftSlots={shiftSlots} friendsMap={friendsMap} />
      </List>
    </Box>
  );
}
